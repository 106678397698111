html {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 16px;
}

body {
  font-family: "Roboto", sans-serif;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #000;
}

#particles-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  width: auto;
  height: auto;
}

.modal-content {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
}

.modal-header {
  border-color: rgba(0, 0, 0, 0.125);
  opacity: 0.6;
}
.modal-header h2 {
  font-weight: 200;
}

.modal-footer {
  border-color: rgba(0, 0, 0, 0.125);
  opacity: 0.6;
}
.modal-body {
  opacity: 0.6;
}
.modal-body input {
  background: #fff;
}

.input-group-prepend span {
  width: 50px;
  color: #fff;
  border: 0 !important;
}

input {
  font-size: 20px !important;
}