$opacity: .6;
$weight: 200;
html {
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-size: 16px;
}

body {
    font-family: "Roboto", sans-serif;
    width: 100%;
    height: 100%;
    overflow: hidden;
    //background: url("/static/login.jpg") center center no-repeat;
    background-color: #000;
    //background-size: cover;
}

#particles-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    width: auto;
    height: auto;
}

.modal-content {
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: #fff;
}

.modal-header {
    border-color: rgba(0, 0, 0, .125);
    opacity: $opacity;

    h2 {
        font-weight: $weight;
    }
}

.modal-footer {
    border-color: rgba(0, 0, 0, .125);
    opacity: $opacity;

    button {
        //font-weight: $weight !important;
    }
}

.modal-body {
    opacity: $opacity;

    input {
        background: #fff;
        //font-weight: $weight !important;
    }
}

.input-group-prepend span {
    width: 50px;
    //background-color: #FFC312;
    //color: black;
    color: #fff;
    border: 0 !important;
}

input {
    font-size: 20px !important;
}
